<template>
  <div>
    <v-card
      flat
      class="datatable-card-background datatable-card-border rounded mb-6"
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
          >
            <app-date-picker
              v-model="queryParams.start_date"
              hide-details
              no-title
              classes="small-input"
              :label="$t('Start Date')"
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
          >
            <app-date-picker
              v-model="queryParams.end_date"
              hide-details
              no-title
              classes="small-input"
              :label="$t('End Date')"
            />
          </v-col>

          <v-col
            cols="12"
            sm="auto"
          >
            <app-branch-select
              v-model="queryParams.branch_id"
              classes="small-input"
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="3"
          >
            <v-autocomplete
              v-model="queryParams.supplier_id"
              :label="$t('Supplier')"
              :items="suppliersList"
              :item-text="item => item.name"
              :item-value="item => item.id"
              outlined
              dense
              hide-details
              class="small-input"
              :color="$_input_color()"
              @input="supplierSelected(queryParams.supplier_id)"
            />
          </v-col>

          <v-col class="text-end">
            <v-btn
              depressed
              small
              class="me-3"
              @click="$_print()"
            >
              <v-icon left>
                mdil-printer
              </v-icon>

              {{ $t('Print') }}
            </v-btn>

            <v-btn
              color="primary"
              depressed
              small
              @click="getData()"
            >
              <v-icon left>
                mdil-magnify
              </v-icon>

              {{ $t('Search') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      flat
      class="datatable-card-background datatable-card-border rounded"
    >
      <v-card-text>
        <v-simple-table
          id="print-area"
          class="print-table"
          dense
        >
          <template #default>
            <thead>
              <tr>
                <invoice-header />
              </tr>

              <tr>
                <td
                  colspan="100"
                  class="font-weight-bold d-none d-print-table-cell"
                >
                  {{ $t('Supplier Name') }}: {{ supplier.name }}
                </td>
              </tr>

              <tr>
                <th class="text-start">
                  {{ $t('Date') }}
                </th>
                <th class="text-start">
                  {{ $t('Type') }}
                </th>
                <th class="text-start">
                  {{ $t('Invoice No.') }}
                </th>
                <th class="text-start">
                  {{ $t('User') }}
                </th>
                <th class="text-center">
                  {{ $t('Credit') }}
                </th>
                <th class="text-center">
                  {{ $t('Debit') }}
                </th>
                <th class="text-center">
                  {{ $t('Balance') }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(data, index) in apiData.data"
                :key="index"
              >
                <template v-if="index == 0">
                  <td
                    colspan="4"
                    class="text-center"
                  >
                    {{ $t('Opening balance') }}
                  </td>

                  <td class="text-center">
                    {{ $_format_number(data.credit) }} $
                  </td>
                  <td class="text-center">
                    {{ $_format_number(data.debit) }} $
                  </td>
                  <td class="text-center">
                    {{ $_format_number(data.balance) }} $
                  </td>
                </template>

                <template v-else>
                  <td>{{ $_format_date(data.action_date) }}</td>

                  <td>
                    <v-chip
                      label
                      small
                      outlined
                      :color="data.type == 'Payment' ? 'green' : 'warning'"
                      :class="data.type == 'Payment' ? 'white--text' : ''"
                    >
                      {{ $t(data.type) }}
                    </v-chip>
                  </td>

                  <td>{{ data.invoice_no }}</td>
                  <td>{{ data.username }}</td>
                  <td class="text-center">
                    {{ $_format_number(data.credit) }} $
                  </td>
                  <td class="text-center">
                    {{ $_format_number(data.debit) }} $
                  </td>
                  <td class="text-center">
                    {{ $_format_number(data.balance) }} $
                  </td>
                </template>
              </tr>
              <tr
                v-if="apiData.totals"
                class="font-weight-bold"
              >
                <td colspan="4" />
                <td class="text-center">
                  {{ $_format_number(apiData.totals.sum_credit) }} $
                </td>
                <td class="text-center">
                  {{ $_format_number(apiData.totals.sum_debit) }} $
                </td>
                <td class="text-center">
                  {{ $_format_number(apiData.totals.balance) }} $
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      apiData: {},
      queryParams: {},
      supplier: {},
    }
  },

  computed: {
    ...mapGetters(['suppliersList'])
  },

  mounted() {
    this.$_section_title({ title: 'Supplier Acc. Stmt.', icon: 'mdil-chart-bar' })
    this.getSuppliersList()
  },

  methods: {
    ...mapActions(['getSuppliersList']),

    supplierSelected(id) {
      this.supplier = this.suppliersList.find(supplier => supplier.id == id)
    },

    getData() {
      axios.get(`supplier/${this.queryParams.supplier_id}/detail-account-statement/`, { params: this.queryParams }).then(res => {
        this.apiData = res.data
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>